// React //
import React from 'react';

// Css //
import './index.css';

// Components Bootstrap //
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

// React Router Dom //
import { Link } from 'react-router-dom';

// Images //

import logo from '../../img/logoClara.png';
import wppIcon from '../../imgLinktree/whatsappIcon.png';
import telegramIcon from '../../imgLinktree/telegram-logo.png';
import instagramIcon from '../../imgLinktree/instagramIcon.png';

//Images Curses //

import reikiAmaDeus from '../../imgLinktree/reikiAmaDeus.jpg';
import cursoErvas from '../../imgLinktree/Esteira de Ervas e Cristais b.jpg';
import bruxariaAncestral from '../../imgLinktree/bruxariaAncestral.jpg';
import pedraBruxas from '../../imgLinktree/pedraBruxas.jpg';
import cursoEspelhos from '../../imgLinktree/cursoEspelhos.jpg';
import reikiDoisTres from '../../imgLinktree/reiki2e3.jpg';
import iniciacaoChamaVioleta from '../../imgLinktree/iniciacaoChamaVioleta.jpg';
import cursoVelas from '../../imgLinktree/cursoVelas.jpg';
// import cachimboSagrado from '../../imgLinktree/cachimboSagrado2.jpg';
import reikiUsui from '../../imgLinktree/reikiUm.jpg';
import iniciacaoMiguel from '../../imgLinktree/espadaMiguel.jpg';
import benzimento from '../../imgLinktree/Benzimento.jpg';
import jornadaDoLouco from '../../imgLinktree/jornadaDoLouco.jpg';

// import jornadaTrivia from '../../imgLinktree/jornadaTrivia.jpg';
import jornadaTrivia2 from '../../imgLinktree/jornadaTrivia2.jpg';

const Linktree = () => {
  return (
    <>
      <div className="containerLinktree">
        <Row>
          <Col className="col1Linktree" xs={12}>
            <h1 className="titleLinktree">
              Rainha da
              <br /> Antiga Religião
            </h1>
            <img src={logo} style={{ width: '270px', margin: '20px 0' }} alt="Logo rainha" />
            <div className="btn-wrap">
              <Link className="btnLinktree" to="/">
                <img style={{ width: '35px', verticalAlign: 'middle', marginRight: '5px' }} src={logo} />
                Website
              </Link>
              <a className="btnLinktree" href="https://www.instagram.com/rainhadaantigareligiao/">
                <img style={{ width: '22px', verticalAlign: 'middle', marginRight: '10px' }} src={instagramIcon} />
                Instagram
              </a>
            </div>
            <h2 className="subTitleLinktree">
              Para agendamento de
              <br /> cerimônias com Ayahuasca
            </h2>
            <button className="btnGloria">
              <img style={{ width: '35px', verticalAlign: 'middle' }} src={wppIcon} />
              <a href="https://wa.me/5511977155459">Gloria Strega</a>
            </button>
          </Col>
          <div>
            {/* <h3 className="titlePage" style={{ textAlign: 'center', marginTop: '50px' }}>
              Aguarde por novidades!
            </h3> */}
            <Col>
              <h3 className="titleAll">Cursos Presenciais</h3>
              <div
                style={{ display: 'flex', gap: '30px 30px', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}
              >
                <Card className="cardCurses" style={{ width: '19rem', height: '800px', display: 'none' }}>
                  <Card.Img src={iniciacaoChamaVioleta} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title className="customTitle">Iniciação à Chama Violeta</Card.Title>
                    <Card.Text className="customText">
                      A Chama Violeta é uma ferramenta muito utilizada atualmente. Estamos vivendo a era do sétimo raio,
                      o raio violeta, a era de Saint Germain. Esses conhecimentos da Chama Violeta, vem dos primórdios
                      dos tempos. Ela sempre existiu e foi utilizada pela Grande Fraternidade Branca por milhares e
                      milhares de anos.
                    </Card.Text>
                    <Card.Text>
                      Data: 06/07/2023
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+PZyytxnkFrQ2ZTRh"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '870px', display: 'none' }}>
                  <Card.Img variant="top" src={reikiAmaDeus} style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title style={{ fontSize: '1.1rem' }} className="customTitle">
                      Reiki Xamânico - AmaDeus
                    </Card.Title>
                    <Card.Text className="customText">
                      Ama Deus é um Sistema xamânico Poderoso de Cura e Ascensão que usa o Amor do Deus Tupã como
                      veículo de Conexão Cósmica e Transmissão de Energia. Os símbolos são multidimensionais e, embora
                      tenham especificidade em seu uso, são imensas suas aplicações. A maioria das suas técnicas usa
                      visualização com a Terceira Visão e imposição das mãos, de forma ritualística xamânica.
                    </Card.Text>
                    <Card.Text>
                      Data: 27/07/2024
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+SEVW3LkIgM45M2Ix"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '850px', display: 'none' }}>
                  <Card.Img src={cursoVelas} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title style={{ fontSize: '1.1rem' }} className="customTitle">
                      Magia e Feitiçaria com Velas
                    </Card.Title>
                    <Card.Text className="customText">
                      Independente da religião a vela é um instrumento importante para ajudar na materialização das
                      preces e dos desejos. Quando se acende uma vela fazendo um pedido ou agradecendo você se conecta
                      diretamente com o Divino e com seu Eu Superior transformando esse momento em troca e abrindo
                      espaço para as possibilidades, afinal a concentração de energia se torna maior.
                    </Card.Text>
                    <Card.Text>
                      Data: 03/08/2024
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+g69XSUKejfkwYmVh"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '810px', display: 'none' }}>
                  <Card.Img src={cursoEspelhos} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title className="customTitle">Magia e Feitiçaria com Espelhos</Card.Title>
                    <Card.Text className="customText">
                      Desde os remotos tempos, o ser humano se reflete no espelho, seja nas águas, nos metais ou nos
                      espelhos da atualidade. O espelho reflete tudo que vier em sua frente e é um grande portal mágico.
                      Esse portal, pode nos mostrar o que quisermos desde que tenhamos coragem, conhecimento e honra
                      para isso.
                    </Card.Text>
                    <Card.Text>
                      Data: 17/08/2024
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+mRxiD6l0we40NmYx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '680px', display: 'none' }}>
                  <Card.Img variant="top" src={cursoErvas} style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title style={{ fontSize: '1.1rem' }} className="customTitle">
                      Magia e Feitiçaria com Ervas
                    </Card.Title>
                    <Card.Text className="customText">
                      Esse curso tem o objetivo de trazer o conhecimento das ervas, de uma forma ancestral e magística
                      sem cunho religioso, porém abordando uma série de conhecimentos espirituais.
                    </Card.Text>
                    <Card.Text>
                      Data: 24/08/2024
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+-tXFc86cBldiMjYx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '770px' }}>
                  <Card.Img variant="top" src={iniciacaoMiguel} style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title style={{ fontSize: '1.1rem' }} className="customTitle">
                      Iniciação à Sagrada Espada de Arcanjo Miguel
                    </Card.Title>
                    <Card.Text className="customText">
                      Nesse curso o iniciado recebe a poderosa espada de Arcanjo Miguel, o que permite o poder de cortar
                      fios energéticos que ligam o paciente/cliente. É feita uma desconexão com o lado das sombras e as
                      amarras que impedem a pessoa de fluir aqui na fisicalidade.
                    </Card.Text>
                    <Card.Text>
                      Data: 18/01/2024
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+11HRk0cZf3Y0ODkx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '840px' }}>
                  <Card.Img variant="top" src={reikiUsui} style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title style={{ fontSize: '1.1rem' }} className="customTitle">
                      Reiki tradicional Usui e Tibetano - Nivel |
                    </Card.Title>
                    <Card.Text className="customText">
                      O objetivo do curso é iniciar o Participante a ser um canal transmissor da Energia REIKI. Os
                      pontos que são tocados se harmonizam, fortalecem e passam a funcionar de maneira mais eficiente.
                      Reiki não tem contra-indicações, nem efeitos colaterais, pois é apenas a inserção de uma onda de
                      equilíbrio que muda a vibração do corpo.
                    </Card.Text>
                    <Card.Text>
                      Data: 25/01/2025
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+qQd4sEWfHIJlZjgx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '860px' }}>
                  <Card.Img variant="top" src={benzimento} style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title style={{ fontSize: '1.1rem' }} className="customTitle">
                      Workshop em Benzimento - Benzedeiros de Santo Antônio
                    </Card.Title>
                    <Card.Text className="customText">
                      Workshop em benzimento, sintonização na Egrégora de Santo Antônio. Tratando de tópicos como:
                      <ul>
                        <li> História e cultura das vovós indígenas, ciganas, bruxas, curandeiras e abuelitas</li>
                        <li> A relação entre as práticas populares de cura e os poderes constituídos</li>
                        <li>Fundamento do Benzimento</li>
                      </ul>
                      E muito mais!
                    </Card.Text>
                    <Card.Text>
                      Data: 15/02/2025
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+UahOaiJZ1ddhNGMx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '780px' }}>
                  <Card.Img src={reikiDoisTres} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title className="customTitle">
                      Reiki Tradicional Usui e Tibetano <br /> - Nível || e |||
                    </Card.Title>
                    <Card.Text className="customText">
                      A Transformação – Nesse nível, também chamado de mental, o iniciado irá trabalhar com situações
                      mentais e emocionais. Nesse nível, as diferentes situações que serão vivenciadas determinam a
                      combinação que faremos com esses símbolos.
                    </Card.Text>
                    <Card.Text>
                      Data: 22/02/2025
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+ArcwzQjFv9w0YWJh"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col className="col2Linktree">
              <h3 className="titleAll">Cursos Online</h3>
              <div className="colTest">
                <Card className="cardCurses" style={{ width: '19rem', height: '900px', display: 'none' }}>
                  <Card.Img src={jornadaTrivia2} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title className="customTitle">Jornada Trivia</Card.Title>
                    <Card.Text className="customText"></Card.Text>
                    <Card.Text>
                      Viajaremos nos domínios da Deusa, desde a sua mais remota história até os dias atuais, nos
                      re-conectando com Seus mistérios e Seus domínios. Aprenderemos como cultuar, como nos dedicar,
                      como consagrar instrumentos, sobre Seus reinos, Suas faces – epítetos, Sua horda e Sua magia e
                      sobre a liberdade de praticar a Bruxaria Hekatina. Esse curso não é para quem não tem uma noção
                      básica de bruxaria.
                    </Card.Text>
                    <Card.Text>Duração: 13 meses – aulas quinzenais às segundas feiras (dia de Hekate)</Card.Text>
                    <Card.Text>
                      Data inicial: 14/12/2024
                      <br />
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btnInfo"
                        href="https://t.me/+9YTC3kJNDjszMzUx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '825px', display: 'none' }}>
                  <Card.Img src={pedraBruxas} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title className="customTitle">Pedra das Bruxas</Card.Title>
                    <Card.Text className="customText">
                      As pedras das Bruxas são um oráculo composto por 13 pedras, cada uma com um símbolo. Como oráculo,
                      constituído por 13 símbolos eficazes em adivinhação. Como ferramentas utilizadas e, magias são uma
                      conexão muito profunda com a bruxaria num geral e com deidades num geral também, pois trata-se de
                      um oráculo muito versátil e muito intuitivo.
                    </Card.Text>
                    <Card.Text>
                      Data: 20/08/2024
                      <br /> Das 14h às 20h
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+2u9yRy2ogFJjZTcx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '800px' }}>
                  <Card.Img src={jornadaDoLouco} variant="top" style={{ height: '307px' }} />
                  <Card.Body>
                    <Card.Title className="customTitle">Curso de Tarot - A Jornada do Louco</Card.Title>
                    <Card.Text className="customText">
                      O jogo de Tarot é um instrumento esotérico de conhecimento, composto por um baralho de 78 cartas
                      (22 arcanos maiores e 56 arcanos menores). Através da leitura dos jogos dessas cartas, é possível
                      fazer uma série de inferências sobre passado, presente e futuro.
                      <Card.Text>
                        <br /> Duração de 4 mêses com aulas semanais. <br />
                        Data inicial: 18/02/2025
                      </Card.Text>
                    </Card.Text>

                    <div className="wrapContents">
                      <a
                        className="btnInfo"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/+YO7ju0rEQJg5Y2Qx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="cardCurses" style={{ width: '19rem', height: '990px' }}>
                  <Card.Img src={bruxariaAncestral} variant="top" />
                  <Card.Body>
                    <Card.Title className="customTitle">Bruxaria Ancestral</Card.Title>
                    <Card.Text className="customText"></Card.Text>
                    <Card.Text>
                      Nesse curso iremos aprender O que é bruxaria, ética na bruxaria, paganismo, necromancia, Deuses
                      Psicopombos e Deuses dos mortos, ancestrais da bruxaria e da Antiga Religião, como praticar
                      bruxaria ancestral, cultos ancestrais, quatro elementos e quatro pontos cardeais, quatro direções
                      na ancestralidade, círculo dos ancestrais, técnicas de proteção, o poder das velas, das ervas, dos
                      cristais e diversos outros tópicos.
                    </Card.Text>
                    <Card.Text>
                      Duração: 9 meses - uma aula por semana, todas as aulas serão por áudio aula ou vídeo aula e também
                      serão apostiladas e com material de apoio para estudos.
                      <br /> <br />
                      Data inicial: 06/03/2025
                    </Card.Text>
                    <div className="wrapContents">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btnInfo"
                        href="https://t.me/+n8bCMMfTxV9jM2Qx"
                      >
                        <img
                          style={{ width: '25px', verticalAlign: 'middle', marginRight: '5px' }}
                          src={telegramIcon}
                        />
                        Maiores Informações
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Linktree;
